import React, { Component, Fragment } from 'react';
import compact from 'lodash/compact';
import {
  Button,
  ButtonPlain,
  ButtonLink,
  CircleAvatar,
  HistoryLink,
  Icon,
  IconButton,
  SubNav,
  Loading,
  NoContentPlaceholder,
  ContentNotFound,
  ReportModal,
  ContactButton,
  Tooltip,
  VerifyEmailModal,
  ContentCapitalized,
  LicenseBadge,
} from '../../../../common/components';
import { connect as felaConnect } from 'react-fela';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import BreedHover from './BreedHover';
import ListingMeta from './ListingMeta';
import theme from '../../../../theme';
import formatPuppyBiology from '../../../../common/utils/formatPuppyBiology';
import generateInitials from '../../../../common/utils/generateInitials';
import listingReportReasons from '../../../../common/utils/listingReportReasons';
import { getFormattedPhoneNumber } from '../../../../common/utils/format-phone';
import InteractiveBookmark from '../../../../common/components/icons/InteractiveBookmark';
import classNames from 'classnames';
import removeFromQueryString from '../../../../common/utils/remove-from-query-string';
import queryString from 'query-string';
import ContactListingOwnerModal from '../../../../common/components/ContactListingOwnerModal';
import styles from './styles';
import { getListing } from '../../redux/actions';
import { LISTING } from '../../../../common/constants/report-types';
import AdBanner from '../../../../common/components/AdBanner';
import FsAd from '../../../../common/components/Freestar';
import ListingGallery from './ListingGallery';
import { DoAfterLoginContext } from '../../../../contexts/DoAfterLogin';
import getStateData from '../../../../common/utils/getStateData';

class ViewListing extends Component {
  constructor(props) {
    super(props);

    const search = queryString.parse(props.location.search);

    this.state = {
      showLightBox: false,
      showContactModal: !!search.contact && props.user && props.user.get('email_verified'),
      showVerifyEmailModal: !!search.contact && props.user && !props.user.get('email_verified'),
      requestVerification: !!parseInt(search.verification, 10) && !!props.user,
      showReportModal: false,
      showStandOutTooltip: false,
    };
  }

  static contextType = DoAfterLoginContext;

  static fetchData(location, match, user) {
    return [getListing(match.params.listingId)];
  }

  componentDidMount() {
    const { getListing, match, loaded, listing, user } = this.props;

    if (!loaded || listing.get('id') !== match.params.listingId) {
      getListing(match.params.listingId);
    }

    const footer = document.getElementById('fs-sticky-footer');
    if (footer) {
      footer.style.display = 'block';
    }

    if (user) {
      if (window?.freestar) {
        window.freestar.queue.push(function() {
          window.freestar.deleteVideo('FreeStarVideoAdContainer_Slider');
          window.freestar.deleteVideo('FreeStarVideoAdContainer_Slider_Mobile');
        });
      }
    } else {
      if (window?.freestar) {
        window.freestar.queue.push(function() {
          window.freestar.newVideo('FreeStarVideoAdContainer_Slider');
          window.freestar.newVideo('FreeStarVideoAdContainer_Slider_Mobile');
        });
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const search = queryString.parse(this.props.location.search);
    const prevSearch = queryString.parse(prevProps.location.search);

    if (prevSearch.verification !== search.verification) {
      this.setState({ requestVerification: !!parseInt(search.verification, 10) && !!user });
    }

    if (prevSearch.contact !== search.contact) {
      this.setState({
        showContactModal: !!search.contact && user && user.get('email_verified'),
        showVerifyEmailModal: !!search.contact && user && !user.get('email_verified'),
      });
    }

    if (!!prevSearch.report !== !!search.report) {
      this.setState({ showReportModal: !!search.report && !!user });
    }
  }

  componentWillUnmount() {
    const { resetListing, resetSendMessageToListingOwner } = this.props;
    resetListing();
    resetSendMessageToListingOwner();
  }

  navigateToViewProfile = () => {
    const { history, seller, location } = this.props;
    history.push(`/profile/${seller.get('id')}`, { prevLocation: location, backTo: 'Listing' });
  };

  shouldShowContactModal() {
    const { listing, user, sendMessageLoaded } = this.props;
    return !!(
      this.state.showContactModal &&
      listing &&
      (!listing.get('conversation') || sendMessageLoaded) &&
      user &&
      listing.get('seller') !== user.get('id')
    );
  }

  renderHeader = () => {
    const { listing, user } = this.props;
    let titleText = null;
    if (user && listing.get('seller') === user.get('id') && !user.get('is_approved_seller')) {
      titleText = `This Listing is Pending Approval`;
    } else if (user && listing.get('seller') === user.get('id')) {
      titleText = `This is Your Listing`;
    }

    return <SubNav titleText={titleText} user={user} listing={listing} />;
  };

  renderBreedTypes = () => {
    const { listing, location } = this.props;
    if (listing.get('breeds').size === 1) {
      return <BreedHover breed={listing.get('breeds').first()} location={location} />;
    }
    return listing.get('breeds').map((breed, index) => {
      return (
        <Fragment key={breed.get('id')}>
          <BreedHover breed={breed} location={location} />{' '}
          {index !== listing.get('breeds').size - 1 ? <div style={{ margin: '0 7px', paddingTop: 1 }}>/</div> : null}
        </Fragment>
      );
    });
  };

  renderFavoriteTag = () => {
    const { listing, removeFavoriteListing, addFavoriteListing, isMobile, styles } = this.props;

    return (
      <div
        className={styles.favoriteIcon}
        aria-label={'bookmark' + (listing.get('favorite') ? ' bookmarked' : '')}
        onClick={
          listing.get('favorite')
            ? () => removeFavoriteListing(listing.get('id'))
            : () => {
                this.context.doAfterLogin(() => {
                  addFavoriteListing(listing.get('id'));
                });
              }
        }
        style={{ marginLeft: isMobile ? 0 : '12px' }}
      >
        <InteractiveBookmark
          stroke={isMobile ? theme.colors.white : theme.colors.orange}
          fill={listing.get('favorite') ? theme.colors.orange : null}
        />
      </div>
    );
  };

  renderPupInfo = () => {
    const { listing, styles } = this.props;
    const user = listing.get('user');

    const location = this.formatLocationWithoutCountryCode(user?.get('formatted_location') || '');

    return (
      <div className={styles.pupInfoWrapper}>
        <div className={styles.pupNameWrapper}>
          <h1 className={styles.pupName} aria-label="Listing Name">
            {listing.get('name')}
          </h1>
          {this.renderFavoriteTag()}
        </div>
        <div className={styles.breedTypesContainer}>{this.renderBreedTypes()}</div>
        <div>{formatPuppyBiology(listing.get('sex'), listing.get('birthdate'))}</div>
        <div>
          <HistoryLink
            className={styles.locationLink}
            backTo="Listing"
            to={`/find-a-puppy/${this.formatRedirectUrlFromLocation(location)}`}
          >
            {location}
          </HistoryLink>
        </div>
      </div>
    );
  };

  formatLocationWithoutCountryCode(location) {
    const locationArray = location.split(', ');
    if (locationArray.length > 2) {
      locationArray.length = 2;
    }
    return locationArray.join(', ');
  }

  formatRedirectUrlFromLocation(location) {
    const [state, city] = location
      .split(', ')
      .filter(string => string !== 'US')
      .reverse();

    const matchLocation = getStateData(state);

    if (matchLocation) {
      return `${matchLocation.state}/${city}`.toLowerCase();
    }

    return '';
  }

  renderShippingInfo = () => {
    const { listing, styles } = this.props;
    const strippedList = compact([
      listing.get('local_delivery_available') && 'Delivery',
      listing.get('pickup_available') && 'Pickup',
      listing.get('shipping_available') && 'Shipping',
    ]);
    if (strippedList.length > 0) {
      return (
        <div className={styles.shippingInfo}>
          <strong>Available for:</strong>
          {listing.get('local_delivery_available') && <div>Local Delivery</div>}
          {listing.get('pickup_available') && <div>Pickup</div>}
          {listing.get('shipping_available') && <div>Shipping</div>}
        </div>
      );
    }
    return null;
  };

  renderAboutMe = () => {
    const { styles, listing } = this.props;

    return (
      <div className={styles.aboutMe}>
        <h2>All About Me!</h2>
        {listing.get('description') ? (
          <p aria-label="Listing Description">{listing.get('description')}</p>
        ) : (
          <p>
            Nothing written about me yet. <br /> <strong>Contact them</strong> and ask why!
          </p>
        )}
      </div>
    );
  };

  goToBuyerTips = () => {
    window.open('https://puppies.com/blog/articles/buyer-tips', '_blank');
  };

  renderWhyImSpecial = readableAttributes => {
    const { styles } = this.props;
    const { showStandOutTooltip } = this.state;

    const attributes = readableAttributes.map(attribute => {
      return (
        <div className={styles.attribute} key={attribute}>
          <Icon icon="FatCheck" size={16} fill={theme.colors.green} />
          <div>{attribute}</div>
        </div>
      );
    });

    return (
      <>
        <div className={styles.whyImSpecial}>
          <div className={styles.standOutTitleContainer}>
            <h2 className={styles.standOutTitle}>Why I stand out</h2>
            <div className={styles.standOutTip}>
              <div
                onMouseEnter={() => this.setState({ showStandOutTooltip: true })}
                onMouseLeave={() => this.setState({ showStandOutTooltip: false })}
                onTouchStart={() => {
                  if (!showStandOutTooltip) {
                    this.setState({ showStandOutTooltip: true });
                  }
                }}
                onClick={() => {
                  this.goToBuyerTips();
                }}
                className={styles.standOutHover}
              >
                <IconButton icon={'Info'} variant={'blue'} size={18} />
                <div
                  className={styles.standOutTipContainer}
                  onClick={() => {
                    this.setState({ showStandOutTooltip: false });
                  }}
                >
                  <Tooltip show={showStandOutTooltip}>
                    These items are self-reported, be sure to verify with the seller and review our buyer's tips.
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.specialList}>
            {attributes.length > 0 ? (
              attributes
            ) : (
              <div className={styles.attribute}>
                <Icon icon="FatCheck" size={16} fill={theme.colors.green} />
                <div>Ask my owner why!</div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.midAdContainer}>
          <FsAd slotId="why-i-am-special" placementName="puppies_details_small_rec_right1" />
        </div>
      </>
    );
  };

  renderPriceBox = () => {
    const { listing, styles } = this.props;

    const boxClasses = {
      [styles.priceBox]: true,
      [styles.priceBoxOnHold]: listing.get('status') === 'on_hold',
      [styles.priceBoxSold]: listing.get('status') === 'sold',
    };

    const strippedList = compact([
      listing.get('local_delivery_available') && 'Delivery',
      listing.get('pickup_available') && 'Pickup',
      listing.get('shipping_available') && 'Shipping',
    ]);

    return (
      <div className={classNames(boxClasses)}>
        <div className={styles.priceBoxInner}>
          <div className={listing.get('status') !== 'for_sale' ? styles.priceBoxContainer : null}>
            {listing.get('status') === 'for_sale' ? (
              <React.Fragment>
                <div style={{ textAlign: 'center' }}>
                  <div className={styles.listingPrice}>${listing.get('price').toLocaleString()}</div>
                </div>
              </React.Fragment>
            ) : (
              <div className={styles.listingStatusWrapper}>
                <div className={styles.listingStatus}>{listing.get('status') === 'on_hold' ? `HOLD` : 'SOLD'}</div>
                <div className={styles.listingStatusSubText}>
                  {listing.get('status') === 'on_hold' ? `This listing is on hold` : 'This listing has been sold'}
                </div>
              </div>
            )}
          </div>
          <div className={styles.verticalRule} />
          <div className={styles.priceBoxAvailability}>
            {strippedList.length > 0 ? (
              this.renderShippingInfo()
            ) : (
              <div className={styles.shippingInfo}>
                <div style={{ lineHeight: 1.4, textAlign: 'center' }}>
                  <strong>Contact me for pickup/delivery info!</strong>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderContactBox = () => {
    const { listing, seller, user, styles } = this.props;

    const hasConversation = listing.get('conversation');
    const isOwnListing = user && listing.get('seller') === user.get('id');

    const profileUrl = seller.get('premierProfile')
      ? `/premier/${seller.getIn(['premierProfile', 'slug'])}`
      : `/profile/${seller.get('id')}`;

    return (
      <div className={styles.contactBoxContainer}>
        <div className={styles.contactBoxInner}>
          <div className={styles.contactBoxUser}>
            <div className={styles.contactBoxName}>
              <div className={styles.offeredByLabel}>Offered By</div>
              <HistoryLink to={profileUrl} backTo="Listing">
                {seller.get('premierProfile') ? (
                  <>{seller.getIn(['premierProfile', 'name'])}</>
                ) : (
                  <>
                    {seller.get('first_name').replace(/^w/, c => c.toUpperCase())} {seller.get('last_initial')}
                  </>
                )}
              </HistoryLink>
              <br />
              <HistoryLink to={profileUrl} backTo="Listing" className={styles.testimonialLink}>
                View Profile
              </HistoryLink>
            </div>
            <a
              target="_blank"
              href="https://help.puppies.com/article/129-buyer-tips"
              rel="noopener noreferrer"
              className={styles.buyerTipsLink}
            >
              <span>Buyer Tips</span>
            </a>
          </div>
          <div className={styles.contactActionContainer}>
            {!isOwnListing &&
              !hasConversation &&
              this.renderContactButton({ contactText: 'CONTACT ME', smallOrange: true })}
            {isOwnListing && this.renderContactButton({ contactText: 'CONTACT ME', smallOrange: true })}
            {!isOwnListing &&
              hasConversation &&
              this.renderContactButton({ contactText: 'CONTACT ME', smallOrange: true })}
          </div>
          <div></div>
        </div>
      </div>
    );
  };

  trackSiteClick = () => {
    const { listing, seller, trackListingWebsiteClick, user } = this.props;
    if (!user || user.get('id') !== seller.get('id')) {
      trackListingWebsiteClick(listing.get('id'));
    }
  };

  renderVerifiedCheck = (label, isVerified) => {
    const { isMobile, styles } = this.props;

    // if (!isVerified) {
    //   return null;
    // }

    return (
      <div className={styles.verificationTag}>
        {isVerified ? (
          <Icon icon="CheckCircle" fill={theme.colors.green} size={isMobile ? 36 : 18} />
        ) : (
          <Icon icon="CircleX" fill={theme.colors.gray} size={isMobile ? 36 : 18} />
        )}
        <span>
          {label} {isVerified ? null : <span style={{ fontWeight: 'bold' }}>Not</span>} Verified
        </span>
      </div>
    );
  };

  renderVerificationTags = () => (
    <Fragment>
      {this.renderVerifiedCheck('ID', this.props.seller.get('id_verified'))}
      {this.renderVerifiedCheck('Email', this.props.seller.get('email_verified'))}
      {this.renderVerifiedCheck('Phone', this.props.seller.get('phone_number_verified'))}
    </Fragment>
  );

  renderContactButton = (options = {}) => {
    const { listing, location, user } = this.props;

    const isUser = !!(user && listing.get('seller') === user.get('id'));

    return (
      <ContactButton
        conversation={listing.get('conversation')}
        user={user}
        isSelf={isUser}
        location={location}
        backTo="Listing"
        onStartContact={requestVerification => this.setState({ showContactModal: true, requestVerification })}
        showVerifyEmailModal={this.state.showVerifyEmailModal}
        onOpenVerify={() => this.setState({ showVerifyEmailModal: true })}
        isRequestVerificationButton={!!options.requestVerificationButton}
        contactText={options.contactText}
        smallOrange={options.smallOrange}
      />
    );
  };

  renderReportButton = () => {
    const { user, listing } = this.props;
    const isOwnListing = user && listing.get('seller') === user.get('id');
    if (isOwnListing) {
      return null;
    }

    return (
      <IconButton
        onClick={() =>
          this.context.doAfterLogin(() => {
            this.setState({ showReportModal: true });
          })
        }
        title="Report"
        icon="Warning"
        variant="red"
      />
    );
  };

  renderPremierProfile = () => {
    const { seller, sellerAccountAge, setActiveModal, isMobile, styles, location, user } = this.props;
    const profileUrl = `/premier/${seller.getIn(['premierProfile', 'slug'])}`;
    return (
      <div className={styles.premierProfileWrapper}>
        <div className={styles.premierProfile}>
          <div className={styles.premierAvatar}>
            <HistoryLink to={profileUrl} backTo="Listing">
              <CircleAvatar
                photo={seller.getIn(['premierProfile', 'photos', 0])}
                initials={generateInitials(seller.getIn(['premierProfile', 'name']))}
                size={isMobile ? 185 : 150}
                verified={seller.get('id_verified')}
                whiteBg
              />
            </HistoryLink>
          </div>
          <div className={styles.offeredBy}>
            <span className={styles.offeredByText}>Offered By</span>
            <br />
            <HistoryLink to={profileUrl} backTo="Listing">
              <strong>
                <ContentCapitalized>{seller.getIn(['premierProfile', 'name'])}</ContentCapitalized>
              </strong>
            </HistoryLink>
          </div>
          <div className={styles.premierWebsite}>
            {user ? (
              seller.getIn(['premierProfile', 'phone']) && (
                <span>{getFormattedPhoneNumber(seller.getIn(['premierProfile', 'phone']))}</span>
              )
            ) : (
              <span>
                <ButtonPlain onClick={() => setActiveModal('signup')}>View phone number</ButtonPlain>
              </span>
            )}

            {seller.getIn(['premierProfile', 'website']) && (
              <a
                onClick={this.trackSiteClick}
                target="_blank"
                rel="noopener noreferrer"
                href={seller.getIn(['premierProfile', 'website'])}
                className={styles.premierWebsite}
              >
                <span>
                  View Website
                  <Icon icon="OpenLink" className={styles.openLinkIcon} fill={theme.colors.blue} size={20} />
                </span>
              </a>
            )}
          </div>
          <div className={styles.premierContact}>
            {this.renderContactButton('CONTACT ME')}
            <div className={styles.accountAge}>
              A member for <strong>{sellerAccountAge}</strong>
            </div>
            <ButtonLink
              buttonType="round"
              to={{
                pathname: profileUrl,
                state: {
                  prevLocation: location,
                  backTo: 'Listing',
                },
              }}
            >
              View Profile
            </ButtonLink>
          </div>
          <div className={styles.licenseContainer}>
            <LicenseBadge type="federal" user={seller} />
            <LicenseBadge inline type="state" user={seller} />
          </div>
          <div className={styles.verificationTags}>
            {seller.get('isActivePremier') && (
              <div className={styles.verificationTag}>
                <Icon
                  icon="PremierBadge"
                  fill={seller.get('email_verified') ? theme.colors.green : theme.colors.gray}
                  size={isMobile ? 36 : 18}
                />
                <span>Featured Breeder</span>
              </div>
            )}
            {this.renderVerificationTags()}
          </div>
        </div>
      </div>
    );
  };

  renderSellerProfile = () => {
    const { seller, sellerAccountAge, styles, isMobile, isTablet } = this.props;
    return (
      <div className={styles.sellerProfileWrapper}>
        <div className={styles.sellerProfile}>
          <div className={styles.sellerProfileAvatar}>
            <HistoryLink to={`/profile/${seller.get('id')}`} backTo="Listing">
              <CircleAvatar
                size={isMobile || isTablet ? 185 : 90}
                photo={seller.get('photo')}
                initials={generateInitials(seller.get('last_initial'), seller.get('first_name'))}
                alt={'profile'}
                verified={seller.get('id_verified')}
                whiteBg
              />
            </HistoryLink>
          </div>
          <div className={styles.sellerDetailsWrapper}>
            <div className={styles.sellerDetails}>
              <div className={styles.sellerDetailsTitle}>
                <span className={styles.offeredByText}>Offered By</span>
                <HistoryLink to={`/profile/${seller.get('id')}`} backTo="Listing">
                  <strong className={styles.offeredByText}>
                    {seller.get('first_name').replace(/^w/, c => c.toUpperCase())} {seller.get('last_initial')}{' '}
                  </strong>
                </HistoryLink>
              </div>
              <div className={styles.sellerVerificationTags}>{this.renderVerificationTags()}</div>
              <div className={styles.accountMetaContainer}>
                <div className={styles.accountAge}>
                  A member for <strong>{sellerAccountAge}</strong>
                </div>
                <LicenseBadge inline type="federal" user={seller} />
                <LicenseBadge inline type="state" user={seller} />
              </div>
            </div>
          </div>
          <div className={styles.sellerProfileButtonWrapper}>
            {this.renderContactButton({ contactText: 'CONTACT ME', smallOrange: true })}
            <Button buttonType="round" onClick={this.navigateToViewProfile}>
              View Profile
            </Button>
          </div>
        </div>
      </div>
    );
  };

  noContent(statusCode) {
    return (
      <ContentNotFound
        icon="PuppyOutline"
        to="/find-a-puppy"
        buttonText="Find A Puppy"
        title="Sorry, we couldn't find the page you were looking for."
        statusCode={statusCode}
      />
    );
  }

  render() {
    const { listing, styles, location, history, loaded, seller, user, isMobile } = this.props;
    const { requestVerification, showVerifyEmailModal } = this.state;
    const views = listing.getIn(['views', 0, 'count']) || 0;
    if (!loaded.loaded) {
      return <Loading dark center />;
    }

    if (loaded.loaded && loaded.statusCode === 410) {
      return this.noContent(410);
    }

    if (loaded.loaded && !listing.get('id')) {
      return this.noContent(404);
    }

    if (listing.get('isSellerPassedGracePeriod') && (!user || listing.get('seller') !== user.get('id'))) {
      return this.noContent(410);
    }

    const readableAttributes = compact([
      listing.get('health_cert') && 'Health Certificate',
      listing.get('health_guarantee') && 'Health Guarantee',
      listing.get('champion_blood') && 'Champion Blood',
      listing.get('champion_sired') && 'Champion Sired',
      listing.get('pedigree') && 'Pedigree',
      listing.get('registered') && 'Registered',
      listing.get('registrable') && 'Registerable',
      listing.get('show_quality') && 'Show Quality',
      listing.get('vaccinated') && 'Vaccinated',
      listing.get('vet_exam') && 'Vet Exam',
    ]);

    if (!listing.size) {
      return (
        <NoContentPlaceholder
          title="Listing not found."
          buttonText="Find a Puppy"
          onClick={() => this.props.history.push('/find-a-puppy')}
        />
      );
    }
    const isSelf = !!(user && listing.getIn(['seller', 'id']) === user.get('id'));
    return (
      <div className={styles.container}>
        <ListingMeta listing={listing} seller={seller} />
        {this.renderHeader()}
        <div className={styles.wrapper}>
          <div className={styles.listingWrapper}>
            <div className={styles.listingTop}>
              <div className={styles.puppyDetails}>
                <ListingGallery styles={styles} photos={listing.get('photos')} isMobile={isMobile} fallback />
                <div className={styles.puppyDetailsTextContainer}>
                  <div className={styles.puppyDetailsText}>{this.renderPupInfo()}</div>
                </div>
                {!isMobile && this.renderAboutMe()}
              </div>
              <div className={styles.listingRightContainer}>
                <div className={styles.listingContactBox}>
                  {this.renderPriceBox()}
                  {this.renderContactBox()}
                </div>
                {!isMobile && this.renderWhyImSpecial(readableAttributes)}
              </div>
            </div>
            <div className={styles.listingBottom}>
              {isMobile && this.renderAboutMe()}
              {isMobile && this.renderWhyImSpecial(readableAttributes)}
            </div>
            <div className={styles.profilesWrapper}>
              {seller.get('premierProfile') ? this.renderPremierProfile() : this.renderSellerProfile()}
              <div className={styles.facebookAndViews}>
                <div className={styles.profileFooterLeft} />
                <div>
                  <p>
                    <i>{views}</i>{' '}
                    {views === 1 ? (
                      <span>
                        <i>person</i>
                      </span>
                    ) : (
                      <span>
                        <i>people</i>
                      </span>
                    )}{' '}
                    have viewed this listing!
                  </p>
                </div>
                <div>{this.renderReportButton()}</div>
              </div>
              <AdBanner placementName="puppies_details_leaderboard_btf1" slotId="listing-view" />
            </div>
          </div>
        </div>
        {user ? (
          <div>
            <ContactListingOwnerModal
              listing={listing}
              isOpen={this.shouldShowContactModal()}
              requestVerification={requestVerification}
              onClose={() =>
                this.setState({ showContactModal: false, requestVerification: false }, () => {
                  history.replace(
                    location.pathname + removeFromQueryString(location.search, ['contact', 'verification']),
                    {
                      ...location.state,
                    }
                  );
                })
              }
            />
            <ReportModal
              isOpen={this.state.showReportModal}
              onClose={() =>
                this.setState({ showReportModal: false }, () => {
                  history.replace(location.pathname + removeFromQueryString(location.search, ['report']), {
                    ...location.state,
                  });
                })
              }
              listing={listing}
              user={seller}
              reasons={listingReportReasons}
              type={LISTING}
              entity_id={listing.get('id')}
              title={`Report ${listing.get('name')}?`}
              buttonText={`Report Listing`}
              photo={listing.getIn(['photos', 0])}
            />
            <VerifyEmailModal
              isOpen={!isSelf && showVerifyEmailModal}
              onClose={() => this.setState({ showVerifyEmailModal: false })}
              user={user}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isTablet: state.browser.lessThan.tablet,
  isMobile: state.browser.lessThan.mobileLarge,
});

export default compose(felaConnect(styles), connect(mapStateToProps))(ViewListing);
