import { Component } from 'react';
import PropTypes from 'prop-types';

class Logout extends Component {
  static propTypes = {
    logout: PropTypes.func.isRequired,
  };
  componentDidMount() {
    if (window?.freestar) {
      window.freestar?.queue.push(function() {
        window.freestar.newStickyFooter('puppies_adhesion1');
        window.freestar.newVideo('FreeStarVideoAdContainer_Slider');
        window.freestar.newVideo('FreeStarVideoAdContainer_Slider_Mobile');
      });
    }
    this.props.logout();
    this.props.history.replace('/');
  }
  render() {
    return null;
  }
}

export default Logout;
